import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Modal
} from "reactstrap"
import { Link } from "react-router-dom"
import Countdown from "react-countdown"

const Buy = (props) => {
  const {
          status,
          balance,
          walletAddress,
          onClickGetToken,
          // min,
          // max,
          // softcap,
          // hardcap,
          presaleStart,
          presaleEnd,
          // raisedAmount,
          // base,
          // sale,
          // tokenRate,
          // userWithdrawBaseTokens,
          // userWithdrawTokens
        } = props

  // console.log("------------presale start-----------\n", props.presaleStart)
  const [value, setValue] = useState(0)
  const [flag, setFlag] = useState(false)

  let progress = 0
  let softcapPos = 0
  let presale_start = new Date()
  let presale_end = new Date()

  // if(hardcap){
  //   progress = Math.round(parseFloat(raisedAmount)/parseFloat(hardcap)*100)
  //   softcapPos = Math.round(parseFloat(softcap)/parseFloat(hardcap)*100)
  // }else{
  //   progress = 0
  //   softcapPos = 0
  // }
  presale_start = new Date(parseInt(presaleStart)*1000)
  presale_end = new Date(parseInt(presaleEnd)*1000)
  const toggleModal = () => {
    setFlag(!flag)
    removeBodyCss()
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const clickModal = () => {
    toggleModal()
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // console.log("-------11111111----------", completed)
    if (completed) {
      // Render a completed state
        return <>
          </>      
    } else {
      // Render a countdown
      return (
        <>
          <div className="coming-box">
            {days}
            <span>Days</span>
          </div>
          <div className="coming-box">
            {hours}
            <span>Hours</span>
          </div>
          <div className="coming-box">
            {minutes}
            <span>Minutes</span>
          </div>
          <div className="coming-box">
            {seconds}
            <span>Seconds</span>
          </div>
        </>
      )
    }
  }

  const onChangeValue = (e)=>{
    setValue(e.target.value)
  }
  
  const renderSwitchCounter = () =>{
    switch(status){
      case 0:     // QUED - Awaiting start block
        return(
          <Card className="overflow-hidden mb-0 mt-5 mt-lg-0 buy-card" id="countdown-panel">
                <CardHeader className="text-center">
                  <h5 className="mb-0">ICO Presale Countdown</h5>
                </CardHeader>
                <CardBody>
                  <div className="text-center">
                    <h5>Time left to Ico Presale Start:</h5>
                    <div className="mt-4">
                      <div className="counter-number ico-countdown">
                        <Countdown date={presale_start.toLocaleString()} renderer={renderer} />
                      </div>
                    </div>

                    <div className="mt-4">
                      <Button type="button" color="success" className="w-md" disabled>
                        Get Token
                      </Button>
                    </div>

                    <div className="mt-5">
                      <h4 className="font-weight-semibold">1 BNB = 1000000.0 ENGA</h4>
                      <div className="clearfix mt-4">
                        <h5 className="float-end font-size-14 hardcap">20 BNB/4000 BNB</h5>
                      </div>
                      <div className="progress p-1 progress-xl softcap-progress">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: `${progress}%`}}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {/* <div className="progress-label">{`${raisedAmount} BNB(${progress} %)`}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
        )
        break;
      case 1:     // ACTIVE - Deposits enabled, now in Presale
        return(
          <Card className="overflow-hidden mb-0 mt-5 mt-lg-0" id="countdown-panel">
            <CardHeader className="text-center">
              <h5 className="mb-0">ICO Presale Countdown</h5>
            </CardHeader>
            <CardBody>
              <div className="text-center">
                <h5>Time left to Ico Presale End:</h5>
                <div className="mt-4">
                  <div className="counter-number ico-countdown">
                    <Countdown date={presale_end.toLocaleString()} renderer={renderer} />
                  </div>
                </div>
    
                <div className="mt-4">
                  <form onSubmit={(e)=>{onClickGetToken(e,value)}}>
                    <Row>
                      <Col md={{size:4, offset:2}}>
                      {walletAddress ? 
                        <Input
                          type="number"
                          md={4}
                          // min={parseFloat(min)}
                          // max={parseFloat(max)}
                          placeholder={balance}
                          step="any"
                          required
                          onChange={(e)=>{onChangeValue(e)}}
                        /> : 
                        <Input
                          type="number"
                          md={4}
                          placeholder={balance}
                          disabled
                        />
                      }
                        
                      </Col>
                      <Col md={{size:4, offset:0}}>
                      {walletAddress ? 
                        <Button type="submit" color="success" className="w-md">
                          Get Token
                        </Button> :
                        <Button type="submit" color="success" className="w-md" disabled>
                          Get Token
                        </Button>
                      }
                      </Col>
                    </Row>
                  </form>
                </div>
    
                <div className="mt-5">
                  <h4 className="font-weight-semibold">1 BNB = 1000000.0 ENGA</h4>
                  <div className="clearfix mt-4">
                    <h5 className="float-end font-size-14 hardcap">20 BNB/4000 BNB</h5>
                  </div>
                  <div className="progress p-1 progress-xl softcap-progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progress}%`}}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {/* <div className="progress-label">{`${raisedAmount} BNB(${progress} %)`}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )
        break;
      case 2:     // Failure
          return(
            <Card className="overflow-hidden mb-0 mt-5 mt-lg-0" id="countdown-panel">
                <CardHeader className="text-center">
                  <h5 className="mb-0">ICO Presale Countdown</h5>
                </CardHeader>
                <CardBody>
                  <div className="text-center">
                    <h5>ICO Presale ended with the Failure:</h5>
                    
                    <div className="mt-4">
                    {
                      walletAddress ?
                        <Button type="button" color="danger" className="w-md">
                          Withdraw BNB
                        </Button>
                      :
                        <Button type="button" color="danger" className="w-md" disabled>
                            Withdraw BNB
                        </Button>
                    }
                    </div>

                    <div className="mt-5">
                      <h4 className="font-weight-semibold">1 BNB = 1000000.0 ENGA</h4>
                      <div className="clearfix mt-4">
                        <h5 className="float-end font-size-14 hardcap">20 BNB/4000 BNB</h5>
                      </div>
                      <div className="progr41
                      ess p-1 progress-xl softcap-progress">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: `${progress}%`}}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {/* <div className="progress-label">{`${raisedAmount} BNB(${progress} %)`}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
          )
        break;            
    }
    
    
  }

  return (
    <React.Fragment>
      <section className="buy-wrapper flex" id="buy">
        {/* <video autoPlay loop muted  className="hero-background">
          <source src={video} type="video/mp4" ></source>
        </video> */}
        <Container className="hero-container">
          <Row className="align-items-center">
            <Col lg="6">
            
              <div className="">
                <h1 className="text-black font-weight-semibold mb-3 hero-title">
                The Enigma Protocol is a community focused, shoppable, fair launched DeFi Token.
                </h1>
                <ul className="f-20">
                    <li>
                        Reflection
                        <br/>
                        <small className="text-muted mb-0">2% is Redistributed to all existing holders</small>
                    </li>
                    <li>
                        LP Acquisition
                        <br/>
                        <small className="text-muted mb-0">3% is Redistributed to all existing holders</small>
                    </li>
                    <li>
                        Token Burn
                        <br/>
                        <small className="text-muted mb-0">5% is Redistributed to all existing holders</small>
                    </li>
                </ul>
                
              </div>
            </Col>
            <Col lg="5" md="8" sm="10" className="ms-lg-auto">
                {renderSwitchCounter()}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

Buy.propTypes = {
  status: PropTypes.number,
  balance: PropTypes.any,
  walletAddress: PropTypes.string,
  onClickGetToken: PropTypes.func,
  // min: PropTypes.any,
  // max: PropTypes.any,
  // softcap: PropTypes.any,
  // hardcap: PropTypes.any,
  // raisedAmount: PropTypes.any,
  // base: PropTypes.any,
  // sale: PropTypes.any,
  // tokenRate: PropTypes.any,
  presaleStart: PropTypes.any,
  presaleEnd: PropTypes.any,
  // userWithdrawBaseTokens: PropTypes.any,
  // userWithdrawTokens: PropTypes.any,
}

export default Buy
