import React, { useEffect, useState } from "react"
// import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'
import video from '../../../../assets/video/video1.mp4'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Modal
} from "reactstrap"
import { Link } from "react-router-dom"
// import Countdown from "react-countdown"

const Section = () => {
// const Section = (props) => {
//   const {
//           status,
//           balance,
//           walletAddress,
//           onClickGetToken,
//           min,
//           max,
//           softcap,
//           hardcap,
//           presaleStart,
//           presaleEnd,
//           raisedAmount,
//           base,
//           sale,
//           tokenRate,
//           userWithdrawBaseTokens,
//           userWithdrawTokens,
//           tokenAddress
//         } = props

  // console.log("---------props------------");
  // console.log(props);

  // const [value, setValue] = useState(0)
  const [flag, setFlag] = useState(false)

  // let progress = 0
  // let softcapPos = 0
  // let presale_start = new Date()
  // let presale_end = new Date()

  // if(hardcap){
  //   progress = Math.round(parseFloat(raisedAmount)/parseFloat(hardcap)*100)
  //   softcapPos = Math.round(parseFloat(softcap)/parseFloat(hardcap)*100)
  // }else{
  //   progress = 0
  //   softcapPos = 0
  // }
  // presale_start = new Date(parseInt(presaleStart)*1000)
  // presale_end = new Date(parseInt(presaleEnd)*1000)
  const toggleModal = () => {
    setFlag(!flag)
    removeBodyCss()
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const clickModal = () => {
    toggleModal()
  }


  return (
    <React.Fragment>
      <section className="hero-wrapper flex" id="home">
        <video autoPlay loop muted  className="hero-background">
          <source src={video} type="video/mp4" ></source>
        </video>
        <div className=""/>
        <Container className="hero-container">
          <Row className="align-items-center">
            <Col lg="5">
            
              <div className="text-white-50">
                <h1 className="text-white font-weight-semibold mb-3 hero-title">
                  Enigma - Join the crypto revolution and don&apos;t miss your chance to become part of the future!
                </h1>
                <p className="font-size-14">
                  The Enigma Protocol is a community focused, fair launched DeFi Token.
                </p>

                <div className="button-items mt-4">
                  {/* <Link to={{pathname:"https://nexumcoin.com/whitepaper/"}} target="_blank" className="btn btn-success me-1">
                    Get Whitepaper
                  </Link> */}
                  <Link to="#" className="btn btn-light" onClick={clickModal}>
                    How it works
                  </Link>
                </div>
              </div>
            </Col>
            {/* <Col lg="5" md="8" sm="10" className="ms-lg-auto">
                {renderSwitchCounter()}
            </Col> */}
          </Row>
          <Modal
            isOpen={flag}
            toggle={() => {
              toggleModal()
            }}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Presale Information
              </h5>
              <button
                type="button"
                onClick={() => {
                  setFlag(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul style={{listStyleType: 'none'}}>
                <li>
                  <span>Token Name:</span>
                  <span>Enigma</span>
                </li>
                <li>
                  <span>Token Symbol:</span>
                  <span>ENGA</span>
                </li>
                <li>
                  <span>Token Decimals:</span>
                  <span>9</span>
                </li>
                <li>
                  <span>Presale Supply:</span>
                  <span>400,000,000,000,000 ENGA</span>
                </li>
                <li>
                  <span>Token Address:</span>
                  <span>0xb51e49d06a1c8b1c0bd1fee1ff834e9ebd3b0988</span>
                  {/* <span>{process.env.REACT_APP_TOKEN_ADDRESS}</span> */}
                </li>
                <li>
                  <span>Token Rate:</span>
                  <span>100,000,000,000</span>
                </li>
                <li>
                  <span>Softcap:</span>
                  <span>20BNB</span>
                </li>
                <li>
                  <span>Hardcap:</span>
                  <span>4000 BNB</span>
                </li>
                <li>
                  <span>Buy min:</span>
                  <span>0.1 BNB</span>
                </li>
                <li>
                  <span>Buy max:</span>
                  <span>10BNB</span>
                </li>
                <li>
                  <span>Presale Start:</span>
                  <span>{process.env.REACT_APP_START_DATE}</span>
                </li>
                <li>
                  <span>Presale End:</span>
                  <span>{process.env.REACT_APP_END_DATE}</span>
                </li>
                {/* <li>
                  <span>Raised Amount:</span>
                  <span>{`${raisedAmount} BNB`}</span>
                </li>
                <li>
                  <span>Sold Amount:</span>
                  <span>{`${parseFloat(raisedAmount)*2000} ENGA`}</span>
                </li> */}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="Close"
                onClick={() => {
                  toggleModal()
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
        </Container>
      </section>
    </React.Fragment>
  )
}

// Section.propTypes = {
//   // status: PropTypes.number,
//   // balance: PropTypes.any,
//   // walletAddress: PropTypes.string,
//   // onClickGetToken: PropTypes.func,
//   // min: PropTypes.any,
//   // max: PropTypes.any,
//   // softcap: PropTypes.any,
//   // hardcap: PropTypes.any,
//   // raisedAmount: PropTypes.any,
//   // base: PropTypes.any,
//   // sale: PropTypes.any,
//   // tokenRate: PropTypes.any,
//   // presaleStart: PropTypes.any,
//   // presaleEnd: PropTypes.any,
//   // userWithdrawBaseTokens: PropTypes.any,
//   // userWithdrawTokens: PropTypes.any,
//   // tokenAddress: PropTypes.string,
// }

export default Section
