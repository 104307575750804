import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

//Images
import bscscan from "../../../../assets/images/clients/bscscan.png"
import coingecko from "../../../../assets/images/clients/coingecko.png"
import coinmarket from "../../../../assets/images/clients/coinmarket.png"
import certik from "../../../../assets/images/clients/certik.png"
import nomics from "../../../../assets/images/clients/nomics.png"
import watcher from "../../../../assets/images/clients/watcher.png"

const AboutUs = () => {
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  // const [holders, setHolders] = useState(0)
  // const [marketCap, setMarketCap] = useState(0)

  // useEffect(() => {
  //   let xhr = new XMLHttpRequest();
  //   xhr.open("GET", `${process.env.REACT_APP_ENDPOINT}/api?module=token&action=tokeninfo&contractaddress=${process.env.REACT_APP_TOKEN_ADDRESS}&apikey=${process.env.REACT_APP_BSCSCAN_APIKEY}`);
  //   xhr.responseType = "json";
  //   xhr.send();
  //   xhr.onload = function(){
  //       if (xhr.status != 200){ 
  //         alert("Erreur " + xhr.status + " : " + xhr.statusText);
  //       }else{ 
  //         console.log(xhr.response)
  //       }
  //   }; 
  // })

  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">About us</div>
                <h4>What is Enigma(ENGA)?</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="12">
              <div className="text-muted">
                <h4 className="mb-2">The Enigma Protocol is a community focused, fair launched DeFi Token.</h4>
                <p style={{lineHeight: '1.55', fontSize: '16px'}}>
                Enigma is a new generation of crypto currency on the basis of binance blockchain. 
                Enigma is the future in the crypto world, creating our own financial decentralised system which controls
                investments in energy from renewable sources. Every holder will be part of the decision making and practically will be a 
                shareholder with rights to vote. This token will be the native token of the coming Defi.              
                </p>
              </div>
            </Col>
          </Row>

          <hr className="my-5" />

          <Row>
            <Col lg="12">
              <Col lg="12">
                <div className="hori-timeline">
                  <div
                    className="owl-carousel owl-theme  navs-carousel events"
                    id="timeline-carousel"
                  >
                    {step1 ? (
                      <>
                        <Row>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={bscscan}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={coingecko}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={coinmarket}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={nomics}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    {step2 ? (
                      <>
                        <Row>
                          <Col md={6}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={certik}
                                  alt="client-img"
                                  style={{maxHeight: '80px'}}
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={watcher}
                                  alt="client-img"
                                  style={{maxHeight: '50px'}}
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    <div className="owl-nav" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        onClick={() => {
                          setStep1(true)
                          setStep2(false)
                        }}
                        className="border-0"
                        disabled={step1}
                      >
                        <i className="mdi mdi-chevron-left"/>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setStep1(false)
                          setStep2(true)
                        }}
                        className="border-0"
                        disabled={step2}
                      >
                        <i className="mdi mdi-chevron-right"/>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default AboutUs
